<template>
    <teleport to="body">
        <transition name="toggle-overlay">
            <div v-if="isOpen" class="screen-overlay" @click="closeDialog"></div>
        </transition>

        <transition name="toggle-dialog">
            <div v-if="isOpen" :class="[$route.meta.appName ? `dialog-${$route.meta.appName}` : '']" class="dialog">
                <slot>
                    <p class="mt-0 text-center semi-bold">{{ text }}</p>
                </slot>
                <div class="text-center mt-2">
                    <button
                        type="button"
                        class="btn mb-1"
                        :class="{
                            'btn-outline-green': type === MessageType.success || type === MessageType.info,
                            'btn-outline-red': type === MessageType.warning || type === MessageType.error,
                        }"
                        @click="confirmDialog"
                        ref="confirmButton"
                    >
                        <span v-if="!loading">{{ confirmText }}</span>
                        <span class="is-loading dots" v-else>
                            <span class="dot-1"></span>
                            <span class="dot-2"></span>
                            <span class="dot-3"></span>
                        </span>
                    </button>
                    <br />
                    <button class="btn-link" type="button" @click="closeDialog" ref="cancelButton">
                        {{ cancelText }}
                    </button>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup>
    import MessageType from '@/models/MessageType';
    import { nextTick, ref } from 'vue';

    const confirmButton = ref(null);
    const isOpen = ref(false);
    const type = ref(false);
    const loading = ref(false);
    const closeOnConfirm = ref(false);
    const text = ref('');
    const confirmText = ref('');
    const cancelText = ref('');
    const resolvePromise = ref(null);

    function openDialog(dialogText, options = {}) {
        options.type = options.type ?? 'info';
        options.confirmText = options.confirmText ?? 'Confirm';
        options.cancelText = options.cancelText ?? 'Cancel';
        options.closeOnConfirm = options.closeOnConfirm ?? false;

        resolve(false);
        text.value = dialogText;
        type.value = options.type;
        loading.value = false;
        closeOnConfirm.value = options.closeOnConfirm;
        confirmText.value = options.confirmText;
        cancelText.value = options.cancelText;
        isOpen.value = true;
        nextTick(() => {
            if (confirmButton.value) {
                confirmButton.value.focus();
            }
        });
        return new Promise((resolve) => {
            resolvePromise.value = resolve;
        });
    }
    function closeDialog() {
        isOpen.value = false;
        resolve(false);
    }
    function confirmDialog() {
        if (closeOnConfirm.value) {
            isOpen.value = false;
        } else {
            loading.value = true;
        }
        resolve(true);
    }
    function resolve(value) {
        if (resolvePromise.value !== null) {
            const resolve = resolvePromise.value;
            resolvePromise.value = null;
            resolve(value);
        }
    }

    defineExpose({
        openDialog,
        closeDialog,
    });
</script>
