/* eslint-disable prefer-const */
/* eslint-disable prefer-spread */
import { DateTime, IANAZone, Settings as luxonSettings } from 'luxon';
import Lead from './models/Lead';

export const INT_MAX_VALUE = 2147483647;

const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
export const passwordOptions = {
    minLength: 6,
    maxLength: 100,
    requireNonAlphanumeric: true,
    requireDigit: true,
    requireLowercase: true,
    requireUppercase: true,
};

export const validation = {
    required: (value: string) => {
        return value !== null && typeof value === 'string' && value.trim().length > 0;
    },
    email: (value: string) => {
        return !validation.required(value) || emailRegex.test(value);
    },
    password: {
        minLength(value: string) {
            return value.length >= passwordOptions.minLength;
        },
        maxLength(value: string) {
            return value.length <= passwordOptions.maxLength;
        },
        requireNonAlphanumeric(value: string) {
            return !passwordOptions.requireNonAlphanumeric || /[^a-zA-Z0-9]+/.test(value);
        },
        requireDigit(value: string) {
            return !passwordOptions.requireDigit || /[0-9]+/.test(value);
        },
        requireLowercase(value: string) {
            return !passwordOptions.requireLowercase || /[a-z]+/.test(value);
        },
        requireUppercase(value: string) {
            return !passwordOptions.requireUppercase || /[A-Z]+/.test(value);
        },
        all(value: string) {
            return (
                !validation.required(value) ||
                (this.minLength(value) &&
                    this.maxLength(value) &&
                    this.requireNonAlphanumeric(value) &&
                    this.requireDigit(value) &&
                    this.requireLowercase(value) &&
                    this.requireUppercase(value))
            );
        },
    },
};

export function url(path: string, query: any) {
    const url = new URL(path, window.location.origin);
    for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
            url.searchParams.set(key, query[key]);
        }
    }
    return url.searchParams.toString() ? `${url.pathname}?${url.searchParams.toString()}` : path;
}

export function autoExpandTextarea(textarea: HTMLTextAreaElement) {
    if (!textarea || !(textarea instanceof HTMLTextAreaElement)) {
        return;
    }
    if (!textarea.value) {
        textarea.style.height = '';
        textarea.rows = 0;
        return;
    }
    const currentHeight = textarea.getBoundingClientRect().height;
    if (currentHeight > 0) {
        const offset = currentHeight - textarea.clientHeight;
        const neededHeight = textarea.scrollHeight + offset;
        if (neededHeight != currentHeight) {
            textarea.style.height = textarea.scrollHeight + offset + 'px';
            textarea.rows = 0;
        }
    } else {
        const lines = textarea.value.split('\n').length;
        if (lines !== textarea.rows) {
            textarea.style.height = '';
            textarea.rows = lines;
        }
    }
}

export function autoExpandTextareaListener(e: Event) {
    const target = e.target as HTMLTextAreaElement;
    if (target) {
        autoExpandTextarea(target);
    }
}

export const autoExpandTextareaListeners = {
    change: autoExpandTextareaListener,
    input: autoExpandTextareaListener,
    paste: autoExpandTextareaListener,
};

export function inputDateToDateTime(dateString: string | any[], timeString: string) {
    if (typeof dateString === 'string' && dateString.length > 0) {
        if (typeof timeString === 'string' && timeString.length > 0) {
            return DateTime.fromISO(dateString + 'T' + timeString);
        } else {
            return DateTime.fromISO(dateString);
        }
    }
    return null;
}

export function inputTimeToDateTime(dateTime: DateTime, timeString: string) {
    if (!(dateTime && dateTime instanceof DateTime && dateTime.isValid)) {
        dateTime = DateTime.now().startOf('day');
    }
    if (typeof timeString === 'string' && timeString.length > 0) {
        const newDateTime = DateTime.fromISO(dateTime.toISODate() + 'T' + timeString).startOf('minute');
        if (newDateTime.isValid) {
            dateTime = newDateTime;
        }
    }
    return dateTime;
}

export function dateTimeToInputDate(input: DateTime) {
    if (input && input instanceof DateTime) {
        return input.toISODate();
    }
    return '';
}

export function dateTimeToInputTime(input: DateTime) {
    if (input && input instanceof DateTime) {
        return input.toISOTime({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });
    }
    return '';
}

export async function appendScriptToHead(id: string, url: string) {
    if (document.getElementById(id)) {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.id = id;
        script.onabort = reject;
        script.onerror = reject;
        script.onload = resolve;
        document.head.appendChild(script);
        script.src = url;
    });
}

export function nullableInt(input: string | any[]) {
    let output: number | null = null;
    if (typeof input === 'string' && input.length > 0) {
        const parsed = parseInt(input, 10);
        if (!isNaN(parsed)) {
            output = parsed;
        }
    }
    return output;
}

export function nullToEmpty(input: null) {
    return input === null ? '' : input;
}

export function getMapUrlSearchAddress(lead: Lead) {
    const address = lead.street + ', ' + lead.city + ', ' + lead.state + ' ' + lead.zipCode;
    return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
}

export function getMapUrlDirectionsAddress(lead: Lead) {
    const address = lead.street + ', ' + lead.city + ', ' + lead.state + ' ' + lead.zipCode;
    return 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=' + encodeURIComponent(address);
}

export function formatPercent(amount: number, preventTransform = false) {
    return preventTransform ? `${amount} %` : amount.toLocaleString(undefined, { style: 'percent' });
}

type FormatPriceOptions = {
    locales?: Intl.LocalesArgument;
    options?: Intl.NumberFormatOptions;
};

export const formatNumberPrice = (
    value: number | string | undefined | null,
    { locales = 'en-US', options = { style: 'currency', currency: 'USD' } }: FormatPriceOptions = {}
): string => {
    if (value == null) {
        return (0).toLocaleString(locales, options);
    }
    const numericValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.]/g, '')) : value;
    return numericValue.toLocaleString(locales, options);
};

// export function formatCurrency(amount: number) {
//     return amount.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
// }

export function formatCurrency(
    value: number | string | undefined | null,
    { locales = 'en-US', options = { style: 'currency', currency: 'USD' } }: FormatPriceOptions = {}
) {
    if (value == null) {
        return (0).toLocaleString(locales, options);
    }
    const numericValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.]/g, '')) : value;
    return numericValue.toLocaleString(locales, options);
}

const cleanPhoneFormat = /^\+1\d{10}$/;
const prettyPhoneFormat = /^\(\d{3}\) \d{3}-\d{4}$/;

export function cleanPhone(input: string) {
    if (!input) {
        input = '';
    }
    if (cleanPhoneFormat.test(input)) {
        return input;
    }
    const cleaned = input.replace(/[^\d]/g, '');
    // +12625749400
    if (cleaned.length == 10) {
        return '+1' + cleaned;
    } else if (cleaned.length == 11) {
        return '+' + cleaned;
    } else {
        return input;
    }
}

export function formatPhone(input: string) {
    if (prettyPhoneFormat.test(input)) {
        return input;
    }
    input = cleanPhone(input);
    if (cleanPhoneFormat.test(input)) {
        return '(' + input.substr(2, 3) + ') ' + input.substr(5, 3) + '-' + input.substr(8);
    } else {
        return input;
    }
}

export function roundToStep(value: number, field: { step: number }) {
    const { step } = field;
    if (step === 0.333) {
        value = Math.round(value / (1 / 3)) * (1 / 3);
    } else {
        value = Math.round(value / step) * step;
    }

    return value;
}

export function round(number: number, precision: number) {
    const x = parseInt('1' + '0'.repeat(Math.max(0, precision)));
    return Math.round(number * x) / x;
}

export function roundUpToStepAndClamp(value: number, field: { min: number; max: number; step: number }) {
    const { min, max, step } = field;
    value = Math.max(0, value - min);
    if (step === 0.333) {
        const whole = Math.floor(value);
        const part = Math.ceil((value - whole) * 3) / 3;
        value = whole + part;
    } else {
        value = Math.ceil(value / step) * step;
    }
    return Math.min(max, round(value + min, 3));
}

export function copyValues<T>(target: T, source: Partial<T>) {
    for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key) && Object.prototype.hasOwnProperty.call(target, key)) {
            target[key as keyof T] = source[key as keyof T] as T[keyof T];
        }
    }
    return target;
}

type ImageFile = {
    size: number;
    type: string;
    name: string;
    lastModified: number;
};

export function isImageFile(file: unknown) {
    return (
        typeof file === 'object' &&
        file !== null &&
        'type' in file &&
        ['image/jpeg', 'image/png', 'image/gif'].includes((file as ImageFile).type)
    );
}

export async function resizeImage(file: File) {
    return new Promise((resolve, reject) => {
        if (!isImageFile(file)) {
            reject(new Error('Invalid image'));
            return;
        }

        const img = document.createElement('img');
        const reader: any = new FileReader();

        reader.onload = function (e: { target: { result: string } }) {
            img.onload = async function () {
                let width = img.width;
                let height = img.height;
                let MAX_WIDTH = 1920;
                let MAX_HEIGHT = 1080;

                if (height > width) {
                    [MAX_WIDTH, MAX_HEIGHT] = [MAX_HEIGHT, MAX_WIDTH];
                }

                if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                    const wM = width / MAX_WIDTH;
                    const hM = height / MAX_HEIGHT;

                    if (wM > hM) {
                        height = Math.floor(height / wM);
                        width = MAX_WIDTH;
                    } else {
                        width = Math.floor(width / hM);
                        height = MAX_HEIGHT;
                    }
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Failed to get canvas context'));
                    return;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);
                const blob: any = await canvasToBlobAsync(canvas, file.type, 0.8);
                if (!blob) {
                    reject(new Error('Blob creation failed'));
                    return;
                }

                const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });

                if (newFile.size < file.size) {
                    resolve(newFile);
                } else {
                    resolve(file);
                }
            };

            img.onerror = function () {
                reject(new Error('Image loading error'));
            };
            img.src = e.target?.result as string;
        };

        reader.onerror = function () {
            reject(new Error('File reading error'));
        };
        reader.readAsDataURL(file);
    });
}

export function canvasToBlobAsync(canvas: HTMLCanvasElement, type: string, quality: number) {
    return new Promise((resolve, reject) => {
        try {
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                type,
                quality
            );
        } catch (e) {
            reject(e);
        }
    });
}

export async function previewImage(file: { type: string } & Blob) {
    if (!isImageFile(file)) {
        throw new Error('Invalid image');
    }
    return await getDataUrlFromBlob(file);
}

export function getDataUrlFromBlob(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            if (e.target && e.target.result) {
                resolve(e.target.result as string);
            } else {
                reject(new Error('FileReader returned null or undefined'));
            }
        };
        reader.onerror = function (e) {
            reject(new Error('Error reading blob: ' + (e.target ? e.target.error : 'unknown error')));
        };
        reader.readAsDataURL(blob);
    });
}

export function sentenceJoin(array: any[]) {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        return array.slice(0, -1).join(', ') + ' and ' + array[array.length - 1];
    }
}

export function createCallbacks() {
    const callbacks: Array<Function> = [];
    function callCallbacks(...args: any[]) {
        for (const callback of callbacks) {
            callback.apply(undefined, args);
        }
    }
    function addCallback(callback: Function) {
        if (typeof callback === 'function') {
            callbacks.push(callback);
        }
    }
    return { callCallbacks, addCallback };
}

export function escapeRegex(string: string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function getRandomColor() {
    let r = Math.floor(Math.random() * 256).toString(16);
    r = r.length === 1 ? '0' + r : r;
    let g = Math.floor(Math.random() * 256).toString(16);
    g = g.length === 1 ? '0' + g : g;
    let b = Math.floor(Math.random() * 256).toString(16);
    b = b.length === 1 ? '0' + b : b;
    return '#' + r + g + b;
}

function convertHexColorToHSL(hex: string | any[]) {
    if (!(typeof hex === 'string' && /^#(?:[0-9A-F]{6}|[0-9A-F]{3})$/i.test(hex))) {
        hex = '#000';
    }

    // Convert hex to RGB first
    let r: number, g: number, b: number;
    if (hex.length === 4) {
        // For 3-digit hex
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        // For 6-digit hex
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    } else {
        r = g = b = 0;
    }

    // Normalize to [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Calculate HSL values
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0) {
        h = 0; // Achromatic
    } else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s + '%', l + '%'];
}

export function setThemeColor(themeColor: string) {
    if (!themeColor) return;
    const themeColorHSL = convertHexColorToHSL(themeColor);
    document.documentElement.style.setProperty('--theme-color', themeColor);
    document.documentElement.style.setProperty('--theme-color-hsl', themeColorHSL.toString());
    document.documentElement.style.setProperty('--theme-color-h', themeColorHSL[0].toString());
    document.documentElement.style.setProperty('--theme-color-s', themeColorHSL[1].toString());
    document.documentElement.style.setProperty('--theme-color-l', themeColorHSL[2].toString());
}

export function setLuxonTimeZone(timeZone: string) {
    if (timeZone && IANAZone.isValidZone(timeZone)) {
        luxonSettings.defaultZone = timeZone;
    } else {
        luxonSettings.defaultZone = 'system';
    }
}

// adapted from https://stackoverflow.com/a/6491621 on 2022-12-06
export function getObjectValueAtPath(o: { [x: string]: any }, s: string) {
    // convert indexes to properties
    s = s.replace(/\[(\w+)\]/g, '.$1');
    // strip a leading dot
    s = s.replace(/^\./, '');
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export function parseBool(rawValue: string, fallback: boolean) {
    if (rawValue === 'true') {
        return true;
    } else if (rawValue === 'false') {
        return false;
    } else {
        return fallback;
    }
}

export function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;
    const platform = navigator.platform || '';

    const iosPlatforms = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
    const isIOS = iosPlatforms.includes(platform) || (/Mac/.test(platform) && 'ontouchend' in document);

    const isAndroid = /android/i.test(userAgent);

    const isMobileDevice = /armv/i.test(platform);

    const hasTouchSupport = 'ontouchend' in document.documentElement && navigator.maxTouchPoints > 1;

    return (isIOS || isAndroid || isMobileDevice) && hasTouchSupport;
}

export function detailIsMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;
    const platform = navigator.platform || '';
    const isAndroid = /android/i.test(userAgent);
    const hasTouchSupport = 'ontouchend' in document.documentElement && navigator.maxTouchPoints > 1;
    const iosPlatforms = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
    const isIOS = iosPlatforms.includes(platform) || (/Mac/.test(platform) && 'ontouchend' in document);

    // Verificamos si es un dispositivo móvil (teléfono) o una Tablet
    const isMobileDevice = isAndroid || isIOS;

    // Añadimos una verificación adicional basada en el tamaño de la pantalla
    const isMobileSize =
        (navigator.userAgent.toLowerCase().includes('tablet') && !userAgent.includes('Mac')) ||
        (screen.width < 1024 && isMobileDevice);

    return {
        userAgent,
        platform,
        isAndroid,
        hasTouchSupport,
        isIOS,
        isMobile: isMobileSize,
    };
}
export interface Item {
    value: string;
    id: number | string;
}

export function getPitchId(numero: number, data: Item[]) {
    let id: number | string | undefined;
    for (const item of data) {
        const value = item.value;
        if (value.includes('-')) {
            const [rango] = value.split(' ');
            const [inicio, fin] = rango.split('-').map(Number);
            if (numero >= inicio && numero <= fin) {
                id = item.id;
            }
        }
    }

    return id;
}
